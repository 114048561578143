<template>
  <div>
    <div>
      <main class="page-content">
        <div class="account-page-area">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 account_border-right">
                 <sidebar-dashboard-customer></sidebar-dashboard-customer>
              </div>

              <!-- ///// -->
              <div class="col-lg-9">
                <h3 style="" >Payment History</h3>
                <div v-if="!customerList.length">
                  <notfound :checkdata="initialLoading"></notfound>
                </div>
                <!-- <div> -->
                <div v-else>
                  <div
                    style="
                      float: right;
                      margin-right: 10px;
                      margin-bottom: 10px;
                    "
                  >
                    <button class="btn btn-primary noPrint" :style="printBtn" @click="printCom">
                      Print
                    </button>
                  </div>
                  <b-table id="printMe"
                    :items="customerList"
                    :fields="fields"
                    select-mode="multi"
                    responsive="sm"
                    ref="selectableTable"
                   
                    @row-selected="onRowSelected"
                    :tbody-tr-class="rowClass"
                  >
                    <!-- Example scoped slot for select state illustrative purposes -->
                    <template #cell(selected)="{ rowSelected }">
                      <template v-if="rowSelected">
                        <span aria-hidden="true"
                          ><i class="fas fa-check-square"></i
                        ></span>
                        <!-- <input
                          type="checkbox"
                          name=""
                          id=""
                          :checked="isChecked"
                         
                        /> -->
                        <span class="sr-only">Selected</span>
                      </template>
                      <template v-else>
                        <span aria-hidden="true"
                          ><i class="far fa-square"></i
                        ></span>
                        <!-- <input
                          type="checkbox"
                          name=""
                          id=""
                          :checked="isChecked"
                        /> -->

                        <span class="sr-only">Not selected</span>
                      </template>
                    </template>
                  </b-table>
                  <!-- <div class="row">
                    <div class="col-md-2">
                      <b-button
                        class="btn btn-success"
                        size="sm"
                        style="width: 115px"
                        @click="selectAllRows"
                        >Select all</b-button
                      >
                    </div>
                    <div class="col-md-2">
                      <b-button
                        class="btn btn-warning"
                        size="sm"
                        style="width: 115px"
                        @click="clearSelected"
                        >Clear selected</b-button
                      >
                    </div>
                    <div class="col-md-8" style="text-align: end">
                      <b-button
                        class="btn btn-primary"
                        size="sm"
                        style="width: 115px"
                        @click="payCommission()"
                        >Pay Commission
                      </b-button>
                    </div>
                  </div> -->
                </div>
              </div>

              <!-- ///// -->
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import userService from "@/services/user.service";
import Config from "@/config";
export default {
  data() {
    return {
      paymentInfo: "",
      script: `https://checkout.razorpay.com/v1/checkout.js`,
      boxTwo: "",
      customerList: "",
      path: Config.BASE_URL_IMG,
      initialLoading: true,
      //  { key: 'value', label: 'value(R$)', sortable: true,
      //         formatter: (value, key, item) => value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
      //     },
      fields: [
        { key: "aucId", label: "Id" }, 
        { key: "aucName", label: "Auction Name" }, 
        {
          key: "myBidAmount",
          label: "Win Amount",
          formatter: (myBidAmount) =>
            myBidAmount.toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
            }),
        },
        {
          key: "tokenamount",
          label: "Commission",
          formatter: (tokenamount) =>
            tokenamount.toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
            }),
        },
        {
          key: "balance",
          label: "Balance",
          formatter: (balance) =>
            balance.toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
            }),
        },
        {
          key: "CommissionPaymentStatus",
          label: "Commission Payment Status",
        },
        // "selected",
      ],
      isChecked: "",
      selected: [],
      aucIds: "",
      message: "",
      printBtn:"display:block"
      // sum_Of_Commission: null,
    };
  },
  mounted() {
    if (this.$store.state.auth.user) {
      this.st = this.$store.state.auth.user[0];

      this.getPaymentHistory(166);
    } else {
      this.$router.push("/").catch(() => {
        "myaccount";
      });
    }
  },
  methods: {
    printCom(){
    //  this.printBtn = 'display:none';       
       window.print(); 

    },
    // printFun(){
    //    let c =  document.getElementsByClassName("noPrint")
       
    //   // if(this.printBtn  == 'display:none'){
    //   //   window.print(); 
    //   // }
          
    // },

    
    rowClass(item, type) {
      //alert(item);
      if (!item || type !== "row") return;

      if (item.CommissionPaymentStatus === "true") return "table-success";
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
      // this.isChecked = true;
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
      this.selected = [];
      // this.isChecked = false;
    },
   

   
    closeModal() {
      var self = this;

      setTimeout(function () {
        self.hideModal();
      }, 2000);
    },
    hideModal() {
      this.modelShow = false;
    },

    getPaymentHistory(sellerId) {
      // console.log(sellerId);
      if (sellerId == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getPendingPaymentHistoryAgainstSellerId(sellerId)
          .then((response) => {
            this.customerList = response.data.Data;
            this.initialLoading = false;
            //  this.params = response.data;
            // console.log(this.customerList);
          })
          .catch((e) => {
            console.log(e);
            this.initialLoading = false;
          });
      }
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      if (
        !this.$store.state.auth.user[0].customerId == "" ||
        !this.$store.state.auth.user[0].customerId == " "
      ) {
        this.$router.push("/CustomerPaymentHistory").catch(() => {
          "home";
        });
      } else {
        this.$router.push("/").catch(() => {
          "home";
        });
      }
    }
  },
  filters: {
    currency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      let country = Config.countryCode;
      let crncy = Config.curency;
      let FractionDigits = Config.minFractionDigits;
      const formatter = new Intl.NumberFormat(country, {
        style: "currency",
        currency: crncy,
        minimumFractionDigits: FractionDigits,
      });
      return formatter.format(value);
    },
  },
};
</script>
<style scoped>
th label {
  margin-bottom: 0px;
}
table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}
table tr {
  background-color: #fff;
  border: 1px solid #e1e5e9;
  padding: 0.35em;
  border-radius: 3px;
}
table thead tr:first-child {
  border: 1px solid #0094da;
}
table th,
table td {
  padding: 8px;
  text-align: left;
  color: #202020;
  font-size: 14px;
}

table td:nth-child(4) {
  font-size: 18px;
}

table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  background: #0094da;
  color: #fff;
}

table tbody tr td .btn-invoice {
  background: #0094da;
  color: #fff;
  font-size: 13px;
  padding: 10px 20px;
  border: 0;
  width: 100%;
  border-radius: 5px;
}

tbody tr:nth-child(even) {
  background-color: #eee;
}

tbody tr:nth-child(odd) {
  background-color: #fff;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }
  table caption {
    font-size: 1.3em;
  }
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
    padding: 0;
  }
  table tr {
    border-bottom: 3px solid #e1e5e9;
    display: block;
    margin-bottom: 0.625em;
  }
  table th,
  table td {
    padding: 0.625em;
  }
  table td {
    border-bottom: 1px solid #e1e5e9;
    display: block;
    font-size: 0.8em;
    text-align: right;
    color: #9da9b9;
  }
  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
    color: #656971;
  }
  table td:last-child {
    border-bottom: 0;
  }
  table td:nth-child(4) {
    font-size: 0.8em;
  }

 


}

 @media print {
 #navbarSupportedContent{
    display:none !important;

  }
  .account_border-right {
     display:none !important;
  }
  .noPrint{
     display:none !important;
     visibility: hidden;
     
  }
  
}
</style>
